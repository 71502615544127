/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/signia/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/signia/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-a-leader-in-innovation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-a-leader-in-innovation",
    "aria-label": "signia a leader in innovation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia, a Leader in Innovation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia has been developing technological and high-quality hearing systems for over 130 years. In more than 100 countries across the globe, the company helps people maintain their previous quality of life. As one of the largest and most innovative hearing aid brands, Signia paves the way in the development of technology, leading the industry in innovation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-and-signia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-and-signia",
    "aria-label": "hearing loss and signia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss and Signia"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-hearing-aids.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia’s products have been providing innovative solutions for customers for years. Their wide range of devices offer relief for every person who experiences from hearing loss. From ", React.createElement(_components.a, {
    href: "/hearing-aids/bte",
    className: "c-md-a"
  }, "BTE (Behind-the-ear)"), " to ", React.createElement(_components.a, {
    href: "/hearing-aids/ite",
    className: "c-md-a"
  }, "ITE (In-the-ear)"), " hearing aids, they have a solution to your particular hearing ailment.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most exciting features is their ability to dampen disruptive ambient noise, so that you can obtain maximum comprehension. Thanks to their Nx technology, the innovative hearing aids process a perfect balance between natural sound and speech intelligibility. Through outstanding innovation in microphone technology, the Signia hearing aids also convey an impressive, three-dimensional “tapestry” of sounds. You won’t miss anything! The two worn hearing aids intercommunicate, and their directional microphones are able to localize sources of sound in the room and appropriately amplify them via the loudspeakers in the ear. Signia’s devices has simplified hearing for people with hearing impairment for more than 130 years."), "\n", React.createElement(ButtonCta, {
    copy: "TRY SIGNIA HEARING AIDS",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "modern-connectivity-through-easytek",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modern-connectivity-through-easytek",
    "aria-label": "modern connectivity through easytek permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modern Connectivity Through Easytek"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-easytek.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia connectivity device, easyTek, provides simple operation of their latest hearing aids wirelessly connecting them to your TV, smartphones, any many other Bluetooth-capable devices. Once connected, the sound is transmitted via Bluetooth®. Volume, programs, and audio sources can be controlled with an iOS and Android app. The numerous options and programmable settings are essential additions to your hearing aids, creating a seamless, pleasurable experience.")), "\n", React.createElement(ButtonCta, {
    copy: "TRY SIGNIA HEARING AIDS",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
